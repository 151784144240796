import React from 'react';
import Helmet from '../../Helmet';
import { Layout, Sections } from '../components';
import ConfigurationProvider from '../providers/ConfigurationProvider';
import { aboutMe } from '../utils/me';

export default () => {
  const {
    degree,
    position,
    company,
    shortAboutFirst,
    environment,
    experience,
  } = aboutMe;
  const envs = environment.map(i => i.key).join(', ');
  const techs = environment.map(i => i.techs);
  const message = [...new Set(techs)].join(',');
  return (
    <ConfigurationProvider>
      <Helmet
        title='About Me'
        shortDescription={`I'm a ${
          degree !== position
            ? degree +
              ` with +${experience} years in the industry working as ` +
              position
            : `${degree} with +${experience} years in the industry`
        } at ${company}, focused on ${shortAboutFirst} ${envs} with ${message}`}
        meta={[
          {
            name: 'keywords',
            content: [...new Set(aboutMe.keywords)].join(', '),
          },
        ]}
      />
      <Layout>
        <Sections />
      </Layout>
    </ConfigurationProvider>
  );
};
